import React, { useState, useEffect } from "react";
import Logout from "../../Home/Logout.png";
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
import SideMobile from "../../SideMobile";
import { RxCross1 } from "react-icons/rx";

export default function Leaguehomea(props) {
  const navigate = useNavigate();
  const handleroute = () => {
    navigate(`/leagueleads`);
  };
  const handleroater = () => {
    navigate(`/leagueroast`);
  };
  const handlemeeting = () => {
    navigate(`/leaguemeeting`);
  };
  const handleslip = () => {
    navigate(`/leagueslips`);
  };

  const handlelogout = () => {
    navigate(`/login`);
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button
                  onClick={handlelogout}
                  className="text-black mont-serif delete p-[6px] w-[16rem]"
                >
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sm:hidden lg:block">
        <Sidebar toggleModal={toggleModal} />
      </div>
      <div className="lg:hidden sm:block">
        <SideMobile />
      </div>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="  header bg-white shadow py-4 px-4 lg:block xs:hidden">
          <div className="header-content flex items-center flex-row">
            <form action="#" className="lg:ml-[16rem]">
              <div className="hidden md:flex relative lg:w-[136vh]">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <input
                  id="search"
                  type="text"
                  name="search"
                  className=" mont-serif text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg  w-full h-10 focus:outline-none "
                  placeholder="Search by company member , company or chapter"
                />
              </div>
              <div className="flex md:hidden">
                <a
                  href="#/"
                  className="flex items-center justify-center h-10 w-10 border-transparent"
                >
                  <svg
                    className="h-6 w-6 text-gray-500"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </a>
              </div>
            </form>
            <div
              className="flex justify-center mx-auto m-auto lg:gap-[1rem]"
              style={{ alignItems: "center" }}
            >
              <AiOutlineMail />
              <AiTwotoneBell />
              <FaUserAlt />
            </div>
          </div>
        </header>
        <div className="main-content flex flex-col flex-grow">
          <div className="    lg:p-10 bg-about text-center duration-500 ease-in-out cursor-pointer">
            <h1 className="text-center mont-serif font-semibold text-2xl text-[#005125]	xs:p-[21px] xs:mt-[7px] ">
              Leagues
            </h1>
          </div>
          {/* content started */}
          <div className="flex flex-col lg:flex-row lg:gap-[2rem] items-center xs:justify-center">
            <div className="xs:w-max  grid-card-1 px-3 py-24 w-[55%] fade-in-left">
              <div className="xs:relative xs:left-[9px]   grid grid-cols-2 gap-[21px] text-center w-full cursor-pointer ">
                <div
                  className="  lg:w-[326px] xxl:w-[272px]  xs:w-[188px] lg:h-[214px] xs:h-[123px] image-grid rounded-md hover:scale-105 duration-500 ease-in-out cursor-pointer"
                  onClick={handleroute}
                >
                  <img
                    src={props.bgimage}
                    className="bg-grid absolute z-40 opacity-[0.2] img-22s  lg:w-[325px] lg:h-[212px] xxl:w-[272px]  xs:w-[188px] xs:h-[123px]"
                    alt=""
                  />
                  <h1 className="text-[#005125] mont-serif font-bold text-[20px] lg:mt-[11rem] xs:mt-[5rem]">
                    League Leads
                  </h1>
                </div>

                <div
                  onClick={handleroater}
                  className="lg:lg:w-[326px] xxl:w-[272px]  xs:w-[188px] lg:h-[214px] xs:h-[123px] image-grid rounded-md hover:scale-105 duration-500 ease-in-out cursor-pointer"
                >
                  <img
                    src={props.bgimage}
                    className="image-grid absolute z-40 opacity-[0.2] xxl:w-[272px] img-22s lg:w-[325px] lg:h-[212px]  xs:w-[188px] xs:h-[123px]"
                    alt=""
                  />

                  <h1 className="text-[#005125] mont-serif font-bold text-[20px] lg:mt-[11rem] xs:mt-[5rem]">
                    Roaster
                  </h1>
                </div>
                <div
                  onClick={handlemeeting}
                  className="lg:w-[326px] xxl:w-[272px]  xs:w-[188px] lg:h-[214px] xs:h-[123px] image-grid rounded-md hover:scale-105 duration-500 ease-in-out cursor-pointer"
                >
                  <img
                    src={props.bgimage}
                    className=" image-grid absolute z-40 opacity-[0.2]  lg:w-[325px]  xxl:w-[272px] lg:h-[212px]  xs:w-[188px] xs:h-[123px]"
                    alt=""
                  />

                  <h1 className="text-[#005125] mont-serif font-bold text-[20px] lg:mt-[11rem] xs:mt-[5rem] ">
                    League Meeting
                  </h1>
                </div>
                <div
                  onClick={handleslip}
                  className="lg:w-[326px]  xs:w-[188px] lg:h-[214px] xs:h-[123px] xxl:w-[272px]  image-grid rounded-md hover:scale-105 duration-500 ease-in-out cursor-pointer"
                >
                  <img
                    src={props.bgimage}
                    className="image-grid absolute z-40 opacity-[0.2] img-22s xxl:w-[272px] lg:w-[325px] lg:h-[212px]  xs:w-[188px] xs:h-[123px]"
                    alt=""
                  />

                  <h1 className="text-[#005125] mont-serif font-bold text-[20px] lg:mt-[11rem] xs:mt-[5rem]">
                    Slips
                  </h1>
                </div>
              </div>
            </div>

            <hr className="lg:hidden xs:block   border-dashed border-[1.1px] border-[#a5a0a0] w-[100%] relative top-[-34px]" />

            <div className="grid-content lg:w-[641px] lg:h-[683px;] league-shadow px-3 lg:py-24">
              <div
                className="grid grid-cols-3 lg:gap-[3rem] xs:gap-[4rem]    mx-auto fade-in-right lg:w-[366px]"
                style={{ placeItems: "center" }}
              >
                <div className="content-league lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem] shadow-xl">
                  <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]        ">
                    70
                  </p>
                  <p className="text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px]    xs:whitespace-nowrap	">
                    one to one <br /> Meeting
                  </p>
                </div>

                <div className="content-league lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem] shadow-xl">
                  <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]        ">
                    70
                  </p>
                  <p className="text-[14px] text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px]    xs:whitespace-nowrap	">
                    League leads <br />
                    Given
                  </p>
                </div>
                <div className="content-league lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem] shadow-xl">
                  <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]        ">
                    70
                  </p>
                  <p className="text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px]    xs:whitespace-nowrap	">
                    CBR Given
                  </p>
                </div>

                <div className="content-league lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem] shadow-xl">
                  <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]        ">
                    70
                  </p>
                  <p className="text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px]    xs:whitespace-nowrap	">
                    Visitors
                  </p>
                </div>
                <div className="content-league lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem] shadow-xl">
                  <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]        ">
                    70
                  </p>
                  <p className="text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px]    xs:whitespace-nowrap	">
                    League <br />
                    Received
                  </p>
                </div>
                <div className="content-league lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem] shadow-xl">
                  <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]        ">
                    70
                  </p>
                  <p className="text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px]    	">
                    Revenue Received
                  </p>
                </div>
                <div className="col-span-3 flex justify-center items-center">
                  <div className="content-league  drop-shadow-lg   lg:h-[91.67px] lg:w-[94.67px] xs:h-[71px]  xs:w-[5rem]">
                    {" "}
                    <p className="text-center text-[#005125] mont-serif lg:p-[36px] font-bold lg:text-[19px]       xs:mt-[21px]         ">
                      70
                    </p>
                    <p className="text-center leading-4 mont-serif text-[#005125]   lg:mt-[3px] xs:mt-[35px] ">
                      Confirm Attendance
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
